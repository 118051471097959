import React from 'react';
import { StaticQuery, graphql } from "gatsby";

import PageWrap from 'components/PageWrap';
import { PagePicTitle } from 'components/PagePicTitle';

import './index.scss';

import { BorderedBox } from 'components/BorderedBox';
import { SEO } from 'components/Helmets';

import headerImg from 'media/pages/pricing/Package.png';
import { Section } from 'components/Section';
import IndyLink from 'components/IndyLink';
import PriceLabel from 'components/PriceLabel';
import { Separator } from 'components/Separator';
import CallsToAction from 'components/CallsToAction';

export default function PricingPage(){
    return (
        <PageWrap noSEO={true} path="/pricing">
            <StaticQuery
                query={graphql`
                    query PricesPreviewImg {
                        file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "pages/pricing/Package.png"}) {
                            childImageSharp {
                                fixed(width: 800, height: 400, fit: CONTAIN, background: "white") {
                                    src
                                    height
                                    width
                                }
                            }
                        }
                    }
                `}
                render={function(data){
                    const previewImg = {
                        url: data.file.childImageSharp.fixed.src,
                        height: data.file.childImageSharp.fixed.height,
                        width: data.file.childImageSharp.fixed.width,
                        alt: "Mockup of a physical Mashoom software package"
                    }

                    return <SEO
                        title="Pricing - Mashoom"
                        description="Our pricing is fair, transparent and flexible. Use all the features you need and share your information with your whole team and clients; you only pay for what you use."
                        previewImg={previewImg}
                        path="/pricing"
                    />
                }}
            />
            <PagePicTitle 
                id="PricesTop"
                imageSrc={headerImg}
                imageAlt="Mock Mashoom physical package"
            >
                <BorderedBox>
                    <>
                        <h1>Our Pricing</h1>
                        <p>We have designed our pricing to be clear, transparent and to suit your business. You can test our services without worrying about trial periods, then scale up your use without an immediate overhead.</p>
                        <p className='paraSpaceTop'><strong>We never charge per user</strong>, so no more worrying about buying licenses or sharing passwords.</p>
                    </>
                </BorderedBox>
            </PagePicTitle>
            <Section>
                <div id="PricingTileWrapper">
                    <div class="PricingTile">
                        <h2>Free</h2>
                        <div>
                            <ul>
                                <li>All features</li>
                                <li>Unlimited users</li>
                                <li>Free updates</li>
                                <li>Any device</li>
                                <li>No time limit</li>
                            </ul>
                        </div>
                        <p>Use up to <PriceLabel chargeKey="payThresh" includeTax={false} /> of our use based pricing without limits and in your own time.</p>
                        <p className='paraSpaceTop'>Perfect for trying out Mashoom to see if we are a good fit for you.</p>
                        <CallsToAction contClassName="pricingCTA" types={['SignUp']} />
                    </div>
                    <div class="PricingTile">
                        <h2>Use Based</h2>
                        <div>
                            <ul>
                                <li>All features</li>
                                <li>Unlimited users</li>
                                <li>Free updates</li>
                                <li>Any device</li>
                                <li>Scales to you</li>
                            </ul>
                        </div>
                        <p>A monthly fee calculated from how much you use your account.</p>
                        <p className='paraSpaceTop'>See our <IndyLink adr={"/pricing/calculator"}>pricing calculator</IndyLink> to view our pricing and estimate your fee.</p>
                        <CallsToAction contClassName="pricingCTA" types={['SignUp']} />
                    </div>
                    <div class="PricingTile">
                        <h2>Fixed</h2>
                        <div>
                            <ul>
                                <li>All features</li>
                                <li>Unlimited users</li>
                                <li>Free updates</li>
                                <li>Any device</li>
                                <li>Unlimited use</li>
                            </ul>
                        </div>
                        <p><IndyLink adr={"/about/#ContactUs"}>Get in contact</IndyLink> to set a fixed price for your usage of Mashoom.</p>
                        <p className='paraSpaceTop'>We will ask you for a rough estimate of your usage and offer a fixed monthly fee based on this.</p>
                        <CallsToAction contClassName="pricingCTA" types={['ContactUs']} />
                    </div>
                </div>
            </Section>
            <Separator margin="TOP"/>
            <Section id="PriceDetails" margin="none">
                <BorderedBox>
                    <div className="PDCont">
                        <h2>A few things to note...</h2>
                        <ul>
                            <li>You can move between fixing and use based pricing at any time, just let us know.</li>
                            <li>Payment is taken each month.</li>
                            <li>We accept card payments. Once payment details have been entered we automatically take payment.</li>
                            <li>If payment fails we will notify you and provide a 2 week period to settle the invoice.</li>
                            <li>Invoice payment can be provided, please <IndyLink adr="/about/#ContactUs">get in contact</IndyLink> to learn more.</li>
                        </ul>
                    </div>
                </BorderedBox>
            </Section>
        </PageWrap>
    );
}
